<!-- 巡检项管理 -->
<template>
  <div id="inspection-item">
    <div class="tree">
      <div v-for="(item, index) in typeList" :key="index">
        <div class="tree-title" @click="selectTitle(item)">
          <span :class="iconStatus ? 'search-title' : ''" @click="clickIcon"></span>
          <img src="../../assets/images/dailyWork/iconTitle.png" alt="" />
          <div>{{ item.label }}</div>
        </div>
        <div v-if="iconStatus">
          <div class="tree-content" v-for="(content, index) in item.children" :key="index"
            @click="switchContent(item, content)">
            <span class="line"></span>
            <div class="tree-text" :class="selectContent == content.id ? 'select-tree' : ''">
              <div class="">
                <img v-if="selectContent == content.id" src="../../assets/images/dailyWork/0.png" alt="" />
                <img v-else src="../../assets/images/dailyWork/1.png" alt="" />
              </div>
              <div>{{ content.inspectionName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>{{ typeName }}</span>
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="requestParam">
          <el-form-item label="巡检项名称">
            <el-input v-model.trim="requestParam.itemName" placeholder="请输入巡检项明细名称" clearable>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="query-button">
          <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>

          <el-button v-if="isButtonPower('createItemButtonInspectionItem')" type="primary" icon="el-icon-plus"
            @click="createInspection">新建巡检项</el-button>
          <el-button v-if="isButtonPower('createTypeButtonInspectionItem')" type="primary" icon="el-icon-folder-add"
            @click="createType">新建分类</el-button>
          <el-button v-if="isButtonPower('editTypeButtonInspectionItem')" type="primary" icon="el-icon-edit-outline"
            @click="updateType">修改分类</el-button>
          <el-button v-if="isButtonPower('deleteTypeButtonInspectionItem')" type="primary" icon="el-icon-delete"
            @click="deleteDialog(1)">删除分类</el-button>
        </div>
      </div>

      <el-table class="table" :data="tableData">
        <el-table-column align="center" type="index" label="序号" width="75">
          <template slot-scope="scope">
            <span>{{
              (requestParam.pageNum - 1) * requestParam.pageSize +
              scope.$index +
              1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="itemName" label="巡检项明细">
        </el-table-column>
        <el-table-column align="center" prop="sort" label="排序号">
        </el-table-column>
        <el-table-column align="center" prop="remark" label="备注">
          <template slot-scope="scope">
            <template v-if="isOverflow(scope.row.remark)">
              <el-tooltip :content="scope.row.remark" placement="top" :popper-class="'tooltip'">
                <div class="ellipsis">{{ scope.row.remark }}</div>
              </el-tooltip>
            </template>
            <template v-else>
              <div class="ellipsis">{{ scope.row.remark }}</div>
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" prop="operate">
          <template slot-scope="scope">
            <div class="operate">
              <div v-if="isButtonPower('editItemButtonInspectionItem')">
                <el-button type="text" @click="updateInspection(scope.row)">编辑</el-button>
              </div>
              <div v-if="isButtonPower('deleteItemButtonInspectionItem')">
                <span>|</span>
                <el-button type="text" @click="deleteDialog(scope.row, 0)">删除</el-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page" @click="homePage">首页</button>
        <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
          layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
          @size-change="changeSize" @current-change="changeCurrent">
        </el-pagination>
        <button class="tail-page" @click="tailPage">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
            @change="inputValueChange"></el-input>
          <span class="text">页</span>
          <button class="confirm" @click="confirm">确定</button>
        </div>
      </div>
    </div>
    <el-dialog :title="requestType === 0 ? '新增分类' : '修改分类'" :visible.sync="typeVisible" width="556px"
      @close="dialogClose('typeForm')">
      <div class="medium-dialog">
        <el-form class="dialog-form" ref="typeForm" :inline="true" :model="typeForm" :rules="rules">
          <el-form-item label="巡检项分类" prop="inspectionName">
            <el-input v-model.trim="typeForm.inspectionName" placeholder="请输入巡检项分类" maxlength="30"
              show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="排序号" prop="sort">
            <el-input v-model.trim="typeForm.sort" placeholder="请输入排序号"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="typeVisible = false">取 消</el-button>
        <el-button type="primary" @click="typeConfirm('typeForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建巡检项明细' : '修改巡检项明细'" :visible.sync="inspectionVisible" width="751px"
      @close="dialogClose('inspectionForm')">
      <div class="big-dialog">
        <el-form class="dialog-form" ref="inspectionForm" :inline="true" :model="inspectionForm" :rules="rules">
          <el-form-item label="巡检项明细" prop="itemName">
            <el-input v-model.trim="inspectionForm.itemName" placeholder="请输入巡检项明细"></el-input>
          </el-form-item>
          <el-form-item label="排序号" prop="sort">
            <el-input v-model.trim="inspectionForm.sort" placeholder="请输入排序号"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" :rows="3" v-model.trim="inspectionForm.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="inspectionVisible = false">取 消</el-button>
        <el-button type="primary" @click="inspectionConfirm('inspectionForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="deleteType === 0 ? '删除巡检项' : '删除分类'" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">
        {{ deleteType === 0 ? "确定要删除该巡检项吗" : "确定要删除该分类吗" }}
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { inspectionItemApi } from "@/api/inspectionItem.js";
export default {
  name: "",

  data() {
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        itemName: "",
        inspectionId: "",
      },

      total: 0,
      inputValue: "",
      inspectionVisible: false,
      deleteVisible: false,
      typeVisible: false,
      inspectionForm: {
        inspectionId: "",
        itemName: "",
        sort: "",
        remark: "",
      },
      defaultInspection: {
        inspectionId: "",
        itemName: "",
        sort: "",
        remark: "",
      },
      typeForm: {
        id: "",
        inspectionName: "",
        sort: "",
      },
      defaultType: {
        id: "",
        inspectionName: "",
        sort: "",
      },
      selectType: {},
      deleteType: 0,
      typeName: "",
      requestType: 0,
      iconStatus: true,
      typeList: [
        {
          label: "全部",
          children: [],
        },
      ],
      selectContent: "",
      rules: {
        itemName: [
          { required: true, message: "请输入巡检项明细", trigger: "blur" },
        ],
        sort: [
          { required: true, message: "请输入排序号", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (/^(?=.*[0-9])\d*$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入包含0的正整数"));
              }
            },
            trigger: "blur",
          },
        ],
        inspectionName: [
          { required: true, message: "请输入巡检项分类", trigger: "blur" },
        ],
        allButtonPower: [],
      },
    };
  },
  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "巡检项管理");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryInspectionType();
    this.allButtonPower = sessionStorage.getItem('roleInfo');
  },

  methods: {
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    createInspection() {
      this.requestType = 0;
      Object.assign(this.inspectionForm, this.defaultInspection);
      this.inspectionVisible = true;
    },
    dialogClose(formName) {
      this.$refs[formName].resetFields();
    },
    isOverflow(content) {
      if (content) {
        const lineHeight = 16;
        const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
        // console.log("====>",numLines);
        return numLines >= 3;
      }
    },
    updateInspection(item) {
      this.requestType = 1;
      this.inspectionForm.id = item.id;
      this.inspectionForm.itemName = item.itemName;
      this.inspectionForm.sort = item.sort;
      this.inspectionForm.remark = item.remark;
      this.inspectionVisible = true;
    },
    deleteConfirm() {
      if (this.deleteType === 0) {
        this.deleteInspectionItem();
      } else {
        this.deleteInspectionType();
      }
    },
    createType() {
      this.requestType = 0;
      Object.assign(this.typeForm, this.defaultType);
      this.typeVisible = true;
    },
    updateType() {
      if(this.selectType.id){
        this.requestType = 1;
        this.typeForm.id = this.selectType.id;
        this.typeForm.inspectionName = this.selectType.inspectionName;
        this.typeForm.sort = this.selectType.sort;
        this.typeVisible = true;
      }else{
        this.$message({
          message: '请创建分类后再创建巡检项',
          type: 'warning',
          duration: 3000,
          customClass: "messageText",
        });
      }
    },
    inspectionConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            this.createInspectionItem();
          } else {
            this.updateInspectionItem();
          }
        } else {
          return false;
        }
      });
    },
    typeConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            this.createInspectionType();
          } else {
            this.updateInspectionType();
          }
        } else {
          return false;
        }
      });
    },
    deleteDialog(item, type) {
      this.deleteType = type;
      this.inspectionForm.id = item.id;
      this.deleteVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryInspectionItem();
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryInspectionItem();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryInspectionItem();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryInspectionItem();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryInspectionItem();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryInspectionItem();
    },
    //点击父节点
    selectTitle(item) {
      // this.treeTitle.push(item.label)
      // this.treeTitle = [...new Set(this.treeTitle)];
      console.log(this.treeTitle);
      // const index = this.treeTitle.findIndex(item => item.label === item.label);
      const index = this.treeTitle.indexOf(item.label);
      if (index !== -1) {
        // 对象已存在于数组中，删除它
        this.treeTitle.splice(index, 1);
      } else {
        // 对象不存在于数组中，添加它
        this.treeTitle.push(item.label);
      }
    },
    // 点击子节点
    switchContent(item, content) {
      Object.assign(this.selectType, content);
      this.selectContent = content.id;
      this.requestParam.inspectionId = content.id;
      this.typeName = content.inspectionName;
      this.queryInspectionItem();
    },
    clickIcon() {
      this.iconStatus = !this.iconStatus;
    },
    createInspectionType() {
      this.typeForm.status = 0;
      inspectionItemApi.createInspectionType(this.typeForm).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.typeVisible = false;
          this.queryInspectionType();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updateInspectionType() {
      this.typeForm.status = 0;
      inspectionItemApi.updateInspectionType(this.typeForm).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.typeVisible = false;
          this.queryInspectionType();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    queryInspectionItem() {
      inspectionItemApi.queryInspectionItem(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    createInspectionItem() {
      this.inspectionForm.status = 0;
      this.inspectionForm.inspectionId = this.selectContent;
      inspectionItemApi
        .createInspectionItem(this.inspectionForm)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            this.inspectionVisible = false;
            this.queryInspectionItem();
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
    },
    updateInspectionItem() {
      this.inspectionForm.status = 0;
      inspectionItemApi
        .updateInspectionItem(this.inspectionForm)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            this.inspectionVisible = false;
            this.queryInspectionItem();
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
    },
    deleteInspectionItem() {
      inspectionItemApi
        .deleteInspectionItem(this.inspectionForm.id)
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              message: res.message,
              type: "success",
              duration: 3000,
              customClass: "messageText",
            });
            this.deleteVisible = false;
            this.queryInspectionItem();
          } else {
            this.$message({
              message: res.message,
              type: "error",
              duration: 3000,
              customClass: "messageText",
            });
          }
        });
    },
    queryInspectionType() {
      inspectionItemApi.queryInspectionType().then((res) => {
        if (res.code === 200) {
          debugger
          this.typeList[0].children = res.data;
          Object.assign(this.selectType, res.data[0]);
          this.requestParam.inspectionId = this.typeList[0].children[0].id;
          this.selectContent = this.typeList[0].children[0].id;
          this.typeName = this.typeList[0].children[0].inspectionName;
          this.queryInspectionItem();
        }
      });
    },

    deleteInspectionType() {
      inspectionItemApi.deleteInspectionType(this.selectContent).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.selectType.id=''
          this.queryInspectionType();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#inspection-item {
  width: 100%;
  min-height: 781px;
  font-size: 16px;
  display: flex;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.tree {
  padding-top: 21px;
  width: 400px;
  background: url("../../assets/images/tree-background.png") no-repeat;
  background-size: cover;
  color: #aed6ff;
}

.tree-content {
  height: 40px;
  /* width: 200px; */
  /* line-height: 59px; */
  font-size: 14px;
  margin-left: 52px;
  display: flex;
  align-items: center;
}

.tree-title {
  height: 32px;
  line-height: 32px;
  margin-left: 22px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.tree-title img {
  margin-right: 10px;
}

.tree-title span {
  width: 25px;
  height: 15px;
  background: url(../../assets/images/dailyWork/BG.png);
  background-repeat: no-repeat;
  background-position: -5px -15px;
}

.search-title {
  /* background-position: -5px -15px; */
  background-position: -27px -15px;
}

.line {
  width: 8px;
  padding: 0;
  margin: 0;
  height: 100%;
  background: url(../../assets/images/dailyWork/line.png);
  background-position: 0px 39px;
}

.tree-text {
  margin-top: 40px;
  padding: 2px;
  width: 300px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: pointer;
  /* background: #000; */
}

.tree-text img {
  height: 100%;
}

.tree-text div {
  height: 20px;
}

.tree-text :hover {
  /* background: #0E2751; */
  /* color: #FFC74A; */
  background: #1b386b;
}

.select-tree {
  /* background: #1b386b; */
  color: #ffc74a;
}

.main {
  width: calc(100% - 245px);
  background: url(../../assets/images/content-background.png) no-repeat center;
  background-size: cover;
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

/* 弹窗 */
.medium-dialog {
  margin: 30px 32px 0 27px;
}

.medium-dialog .dialog-form :deep() .el-input,
.medium-dialog .dialog-form :deep() .el-textarea {
  width: 406px;
}

.big-dialog {
  margin: 36px 0 25px 48px;
}

.big-dialog .dialog-form :deep() .el-input {
  width: 539px;
}

.big-dialog .dialog-form :deep() .el-textarea {
  width: 539px;
}

.dialog-form :deep().el-form-item__label {
  width: 81px;
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
}

.dialog-form :deep() .el-input .el-input__inner,
.dialog-form :deep() .el-textarea textarea {
  width: 100%;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}
::v-deep .el-input__inner{
  height: 40px;
}
</style>
<style>
.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}
</style>
